import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
// import { StaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'

const Jumbotron = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  h1 {
    font-size: 42px;
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1100px;
  padding: 70px 0;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  img {
    width: 100%;
  }

  p {
    font-size: 20px;
    line-height: 26px;
  }

  .Image1 {
    width: 450px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .Image2 {
    width: 450px;
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .quote {
    font-family: 'Yellowtail', cursive;
    font-weight: 400;
    font-size: 42px;
    line-height: 63px;
    margin: 80px 60px 80px 60px;
  }
  .hashtag {
    font-weight: 700;
  }
  .quotationMark {
    font-size: 48px;
  }
  .BowlImage {
    margin-top: 60px;
    margin-bottom: 50px;
  }
    @media only screen and (max-width: 767px) {
      .quote {
        font-size: 26px;
        line-height: 39px;
        margin: 80px 60px 80px 60px;
      }
      .Image1 {
        width: 100%;
        float: none;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .Image2 {
        width: 100%;
        float: none;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .quotationMark {
        font-size: 36px;
      }
    }
`;

const Update = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  max-width: 820px;
  margin-top: 50px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 0 auto;
  /* width: 500px; */
  padding: 20px;
    .googleImage {
      width: 100%;
    }
    a {
      width: 350px;
      margin-right: 20px;
    }
    div {
      width: 350px;
      padding-left: 20px;
    }
    h2 {
      margin: 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 0;
      a {
        width: 100%;
        margin-bottom: 20px;
        padding: 20px;
      }
    }
`;

const IndexPage = () => (
  // <StaticQuery
  //   query = {graphql`
  //     query {
  //       dicedRoadOne: file(relativePath: { eq: "dicedRoadOne-min.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 40) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //       dicedRoadTwo: file(relativePath: { eq: "dicedRoadTwo-min.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 40) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //       dicedBowls: file(relativePath: { eq: "DICED-Photo-bowls-min.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 40) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //       googleDykesRd: file(relativePath: { eq: "google-dykesrd.jpeg" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 40) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //   }`}
  //   render = {data =>
      <Layout>
        <SEO 
        title="Dykes Rd Has Been Renamed Diced Rd" 
        description='To celebrate the grand opening of their newest location 15951 Pines Blvd Pembroke Pines, FL 33028 Diced Food will be renaming Dykes road to Diced road.' 
        author='Diced Food'
        type='article'
        url='https://news.dicedfood.com'
        imageUrl='https://news.dicedfood.com/FB-og-Image.png'
        keywords={[`healthy, food, dicedfood, Dykes Rd, Pembroke Pines`]} 
        lang='EN'  />

        <Wrapper>
          <Jumbotron>
                <h1>Dykes Rd Has Been Renamed Diced Rd</h1>
          </Jumbotron>
          <div className='Image1'>
            <img alt='Diced Rd Street Sign' src='/dicedRoadOne-min.jpg' />
          </div>

          <p style={{marginTop: '0'}}>To celebrate the grand opening of their newest location 15951 Pines Blvd Pembroke Pines, FL 33027 Diced Food will be renaming Dykes road to Diced road.  “I was driving through Pembroke Pines when I realized the similarities between these two names. Renaming roads is actually quite easy in Florida” said Diced CEO Otto Perez.</p>
          <p>The mayor’s office put him in touch with the department of names &amp; places and the rest is history.  “At first they were reluctant to rename one of Pembroke Pines’ main arteries, but once I agreed to get a <span className='hashtag'>#PembrokePinesProud</span> tattoo it was a done deal.”</p>
          <p>Mr. Perez remarked that while the office of names &amp; places was surprisingly easy to deal with it was Google that gave the most pushback. “They said it would mess with their algorithms--I told them to deal with it” said Perez.</p>
          <p className='quote'><span className='quotationMark'>“</span>We wanted to mark our territory--to draw a line in the sand, and let the people of Pembroke Pines know that they won’t have to settle for heavy hormone-laden fast food any longer.<span className='quotationMark'>”</span></p>

          <div className='Image2'>
            <img alt='Diced Rd Street Sign'  src='/dicedRoadTwo-min.jpg' />
          </div>

          <p style={{marginTop: '0'}}>Diced is so committed to bringing healthy food to Pembroke Pines that it bought a whole road.  It is a risky tactic but considering it will be the only restaurant with its own road, there could be a significant advantage down the line.  “The irony is that even if someone is going to one of those deep-fried national chains they’re going to have to take our road to get there” Mr. Perez said with a smile.</p>
          <p>The first Diced opened its first location in West Miami in 2015 with a vision of serving wholesome food that you could eat every day.  Diced Pembroke Pines, the fifth location to open in the past four years, has proven there is serious demand for food that is tasty, wholesome, and, of course, quick.</p>
          <p>Neither the mayor’s office nor the Department of Names and Places responded to requests for comment.  But guests who mention “Diced road” or show this article will receive 20% off their purchase until June 30th.</p>

          <img className='BowlImage' alt='Bowls of Food' src='/DICED-Photo-bowls-min.jpg' />

          <Update>
            <a href='https://news.dicedfood.com/google-purchase'><img className='googleImage' alt='Dykes Rd Street Sign'  src='/google-dykesrd.jpeg' /></a>
            <div>
                <h2>Diced Rd changed to Dykes Rd, After Google Purchase</h2>
                <p>After very strong local response and re-appraisal of local objectives, Google has bought back DICED RD, formerly DYKES rd, and renamed it DYKES Rd.</p>
            </div>
          </Update>

        </Wrapper>
      </Layout>
  //   }
  // />
)
export default IndexPage
